<template>
    <div class="container">
      <div style="display: flex;justify-content: space-between;">

            <div>
               <el-button  type="primary" icon="el-icon-plus" style="font-size: 20px;" @click="add">新增气象站</el-button>
                <el-input
                    placeholder="输入设备名称"
                    v-model="device_name"
                    clearable
                    style="width: 200px; margin-left: 20px;"
                    size="small"
                >
                </el-input>
                <el-button size="mini" type="success" icon="el-icon-search" style="margin-left: 10px;height: 30px;" @click="getLessonListHelper">查询</el-button>
            </div>

            <div>
                <el-upload
                    multiple
                    :show-file-list="false"
                    style="display: contents"
                    accept=".xls,.XLS,.xlsx,.XLSX"
                    :limit="limit"
                    :http-request="httpRequest"
                >
                  <!-- <el-button size="mini" type="primary" icon="el-icon-upload" style="margin-left: 10px;height: 30px;" >导入</el-button> -->
                </el-upload>
                <el-button size="mini" type="primary" icon="el-icon-download" style="margin-left: 10px;height: 30px;" @click="exportTable">导出</el-button>
            </div>
        </div>
        <el-table
            :data="tableList"
            v-loading="dataListLoading"
            style="margin-top: 20px; font-size: 18px;"
          >
            <el-table-column width="60" type="index" header-align="center" align="center" label="序号" />
              <el-table-column v-for="(item, key) in col" :key="key" :width="item.width || 'auto'" align="center" :label="item.label">
                <template slot-scope="scope">
                  <div v-if="!item.slot">{{scope.row[item.prop]}}</div>
                  <div v-else>
                    <div v-if="item.prop === 'latLon'">
                        <div>
                          {{ scope.row.positionLng }},
                          {{ scope.row.positionLat }}
                        </div>
                    </div>
                    <div v-if="item.prop === 'status'">
                        <el-tag :type="scope.row.status === '1' ? 'success' : 'danger'" style="font-size: 18px;">{{ scope.row.status === '1' ? '在线' : '离线' }}</el-tag>
                    </div>
                  </div>
                </template>
            </el-table-column>
            <el-table-column
                header-align="center"
                align="center"
                label="操作"
                fixed="right" 
              >
                <template slot-scope="scope">
                  <el-button type="text" @click="upPersonal(scope.row)" style="font-size: 18px;">修改</el-button>
                  <el-popconfirm
                      confirm-button-text='确定'
                      cancel-button-text='再想想'
                      icon="el-icon-info"
                      icon-color="red"
                      :title="`确定要删除设备${scope.row.deviceName}吗?`"
                      @confirm="dePersonal(scope.row.id)"
                  >
                        <el-button 
                          slot="reference" 
                          type="text" 
                          style="color: red; margin-left: 10px; font-size: 18px;"
                        >
                          解绑
                        </el-button>
                  </el-popconfirm>
                </template>
          </el-table-column>
        </el-table>
        <div class="backdd">
        <el-pagination 
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="limit"
            layout="total, prev, pager, next, jumper"
            :total="total"
          >
        </el-pagination>
      </div>
      <addDeviceVue ref="add" />
    </div>
</template>
<script>
import $cookies from "vue-cookies"
import { $device } from "@/services"
import { toXlsx,xlsxToJson } from '@/utils/xlsx.js'
import XLSX from 'xlsx'
import addDeviceVue from './components/addDevice.vue'
export default {
  components:{
    addDeviceVue
  },
  data () {
    return {
      tableList: [],
      device_name: null,
      currentPage: 1,
      total: 0,
      status: -1,
      col:[
        { prop: 'status', label: '设备状态', slot:true },
        { prop: 'deviceName', label: '设备名称' },
        { prop: 'deviceType', label: '设备型号' },
        { prop: 'webId', label: '设备ID' },
        { prop: 'latLon', label: '设备坐标(经纬度)',slot:true  },
        { prop: 'positionAlt', label: '海拔高度', },
        { prop: 'mountAlt', label: '安装高度', },
        { prop: 'mac', label: 'MAC地址', width: '' },
        { prop: 'remarks', label: '备注信息', width: '' },
        // { prop: 'currentUserName', label: '上传用户', width: '' },
      ],
      limit:10,
      dataListLoading:false
    };
  },
  created () {
    this.currentUserName = $cookies.get("currentUserName");
    this.getLessonListHelper()
  },
  methods: {
    handleCurrentChange (page) {
      this.currentPage = page;
      this.getLessonListHelper();
    },
    getLessonListHelper () {
      if (!this.dataListLoading) {
        this.dataListLoading = true
        $device.getConnectPage({
          device_name: this.device_name,
          currentUserName: $cookies.get("userInfo").userName,
          email: $cookies.get("userInfo").email,
          userId: $cookies.get("userInfo").id,
          page: this.currentPage,
          limit: this.limit
        }).then((res) => {
          if (res.result === 1 && res.data) {
            if (res.data.dataCount && res.data.dataList) {
              this.total = res.data.dataCount;
              this.tableList = res.data.dataList
            }
          }
          this.dataListLoading = false 
        })
      }
      
    },
    clear () {
      this.device_name = null;
      this.getLessonListHelper()
    },
    add() {
      // this.$router.push('/gisadd')
      this.$refs.add.setFlag()
    },
    upPersonal (marker) {
      // sessionStorage.setItem("cur", JSON.stringify(marker));
      // this.$router.push({ name: "gisadd", });
      this.$refs.add.setFlag(marker)
    },
    dePersonal (id) {
      $device.delConnectData({id}).then((res) => {
          res.result === 1 ? this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1500,
              onClose: () => {
                  this.getLessonListHelper()
              }
          }) : this.$message.error("操作失败！")
      });
    },
     // 导出
    async exportTable() {
        let params = {} 
        params.device_name = this.device_name
        params.currentUserName = this.currentUserName
        params.page = 1
        params.limit = this.total
        let res = await $device.getConnectPage(params)
        let columns = [{value:'index',label: '序号'}]
        this.col.forEach(item => {
          columns.push(item)
        })
        res.data.dataList.forEach((item,index) => {
          item.index = index + 1
          item.latLon = `${item.positionLng},${item.positionLat}`
          item.status = item.status === '1' ? '在线' : '离线'
        })
        let dataSource = res.data.dataList
        let exclParams = {columns,dataSource}
        toXlsx(exclParams)
    },
    // 导入
    httpRequest(e) {
        let file = e.file
        if (!file) {
          return false
        } else if (!/\.(xls|xlsx)$/.test(file.name.toLowerCase())) {
          this.$message.error('上传格式不正确，请上传xls或者xlsx格式')
          return false
        }
        const fileReader = new FileReader()
        fileReader.readAsBinaryString(file)
        fileReader.onload = (ev) => {
            const data = ev.target.result
            const workbook = XLSX.read(data, {
              type: 'binary',
              cellDates: true
            })
            const exlname = workbook.SheetNames[0]
            const fileData = XLSX.utils.sheet_to_json(workbook.Sheets[exlname])
            let exportData = xlsxToJson({fileData,columns:this.col})
            exportData.forEach((item,index) => {
              $device.setConnectData(item)
              this.getLessonListHelper()
              if(index + 1 === exportData.length) { 
                this.$message.success('导入完成')
              }
            })
        }

    },
},
}
</script>
<style lang="less" scoped>
.container {
  padding: 40px;
}
:deep(.el-table) {
  font-size: 18px !important;
}
</style>