<template>
    <el-dialog
        :title="title"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false"
        :destroy-on-close="true"
        width="600px"
    >
        <el-form 
            :inline="true" 
            :model="form" 
            ref="form"
            label-width="85px" 
            :rules="rules"
        >
            <el-form-item label="设备名称:" prop="deviceName">
                <el-input v-model="form.deviceName"></el-input>
            </el-form-item>
            <el-form-item label="设备类型:">
                <el-select v-model="form.deviceType" placeholder="请选择设备类型" style="width: 180px;" prop="deviceType">
                    <el-option label="4G气象站" value="4G气象站"></el-option>
                    <el-option label="CF8型智能气象站" value="CF8型智能气象站"></el-option>
                    <el-option label="WJ-A型气象站" value="WJ-A型气象站"></el-option>
                    <el-option label="WJ-B型气象站" value="WJ-B型气象站"></el-option>
                    <el-option label="其他型号气象站" value="其他型号气象站"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="MID:" v-if="form.deviceType === '4G气象站'" prop="device">
                <el-input v-model="form.device"></el-input>
            </el-form-item>
            
            <el-form-item label="MAC:" v-else prop="device">
                <el-input v-model="form.device"></el-input>
            </el-form-item>
            <el-form-item label="设备ID:" prop="webId">
                <el-input v-model="form.webId" disabled placeholder="点击地图后自动生成"></el-input>
            </el-form-item>
            <el-form-item label="安装地址:" prop="address">
                <el-input v-model="form.address" disabled placeholder="点击地图后自动生成" style="width: 460px;"></el-input>
            </el-form-item>
            <el-form-item label="经度:" prop="positionLng">
                <el-input v-model="form.positionLng" disabled placeholder="点击地图后自动生成"></el-input>
            </el-form-item>
            <el-form-item label="纬度:" prop="positionLat">
                <el-input v-model="form.positionLat" disabled placeholder="点击地图后自动生成"> </el-input>
            </el-form-item>
            <div class="map">
                <el-amap 
                    vid="amapDemo"
                    :plugin="plugin"
                    :events="events"
                    :center="center"
                    :zoom="zoom"
                >
                    <el-amap-marker v-if="center" :position.sync="center"></el-amap-marker>
                </el-amap>
            </div>
            <el-form-item label="海拔高度:" style="margin-top: 20px;">
                <el-input v-model="form.positionAlt"></el-input>
            </el-form-item>
            <el-form-item label="安装高度:" style="margin-top: 20px;">
                <el-input v-model="form.mountAlt"></el-input>
            </el-form-item>
            <el-form-item label="安装图片:" style="margin-top: 20px;">
                <div style="display: flex;">
                    <div style="width: 150px;">
                        <el-upload
                            class="avatar-uploader"
                            action="https://kxwj.cn/server/common/upload"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess1"
                            :before-upload="beforeAvatarUpload"
                        >
                            <img v-if="imageUrl1" :src="imageUrl1" class="avatar" style="width: 150px; height: 135px;">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload> 
                        <el-select v-model="imageInfo[0].id" placeholder="请选择" style="margin-top: 20px;" @change="handleChange">
                            <el-option
                                v-for="item in columns2"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                    <div style="width: 150px;margin-left: 40px;">
                        <el-upload
                            class="avatar-uploader"
                            action="https://kxwj.cn/server/common/upload"
                            :show-file-list="false"
                            :on-success="handleAvatarSuccess2"
                        >
                            <img v-if="imageUrl2" :src="imageUrl2" class="avatar" style="width: 150px; height: 135px;">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                        <el-select v-model="imageInfo[1].id" placeholder="请选择" style="margin-top: 20px;" @change="handleChange1">
                            <el-option
                                v-for="item in columns2"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="备注:">
                <el-input type="textarea" v-model="form.remarks" style="width: 460px;"></el-input>
            </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
import axios from 'axios';
import $cookies from "vue-cookies";
import { $device } from "@/services";
export default {
    name:'addDeviceVue',
    data() { 
        return {
            dialogVisible:false,
            form:{ 
                deviceName:'',
                deviceType:'CF8型智能气象站',
                webId:'',
                address:'',
                positionLng:'',
                positionLat:'',
                positionAlt:'',
                mountAlt:'',
                remarks:'',
                // userId:$cookies.get('userInfo').id
            },
            title:'新增',
            center: [116.40, 39.90],
            zoom: 10,
            plugin: [{
                enableHighAccuracy: true,   //是否使用高精度定位，默认true
                timeout: 100,               //超过10秒后停止定位，默认：无穷大
                convert: true,          //自动偏移后的坐标为高德坐标，默认：true
                showButton: true,       //显示定位按钮，默认：true
                buttonPosition: 'RB',  //定位按钮停靠位置，默认'LB'，左下角
                showMarker: false,      //定位成功后在定位到的位置显示标记，默认：true
                showCircle: false,      //定位成功后用圆圈表示定位精度范围，默认：true
                panToLocation: true,   //定位成功后将定位到的位置作为地图中心点，默认true
                zoomToAccuracy: true,   //定位成功后调整地图视野范围使定位位置及精度范围视野内可见，默认：false
                extensions: "all",
                pName: "Geolocation",     //AMap-Geolocation 定位插件
                events: {
                    init (o) {
                        // o 是高德地图定位插件实例
                        const that = this
                        o.getCurrentPosition((status, result) => {
                            if (result && result.position) {
                                that.center = [result.position.lng, result.position.lat]
                                that.position = [result.position.lng, result.position.lat]
                                that.loaded = true;
                                that.zoom = 14;
                                that.$nextTick();
                            }
                        })
                    }
                }
            
            }],
            events: {
                click: (e) => {
                    let that = this
                    this.center = [e.lnglat.lng, e.lnglat.lat]
                    this.form.positionLng = e.lnglat.lng
                    this.form.positionLat = e.lnglat.lat
                    let url = `https://restapi.amap.com/v3/geocode/regeo?output=JSON&location=${[e.lnglat.lng, e.lnglat.lat]}&key=0ca7fee066a1fa548ebef6e4730fb4c9&extensions=base&batch=false`
                    axios.get(url).then(res => {
                        that.form.address = res.data.regeocode.formatted_address
                        let time = new Date().getTime();
                        that.form.webId = res.data.regeocode.addressComponent.towncode + time
                    }).catch(error => {
                        console.log(error);
                    });
                }
            },
            rules:{
                'deviceName': [
                    { required: true, message: '请输入设备名称', trigger: 'blur' },
                ],
                'deviceType':[
                    { required: true, message: '请选择设备类型', trigger: 'blur' }
                ],
                'device':[
                    { required: true, message: '请输入设备标识', trigger: 'blur' }
                ],
                'webId': [
                    { required: true, message: '请点击地图', trigger: 'blur' }
                ],
                'address': [
                    { required: true, message: '请点击地图', trigger: 'blur' }
                ],
                'positionLng':  [
                    { required: true, message: '请点击地图', trigger: 'blur' }
                ],
                'positionLat':  [
                    { required: true, message: '请点击地图', trigger: 'blur' }
                ],

            },
            imageUrl1:'',
            imageUrl2:'',
            imageInfo:[
                {
                    id:'',
                    name:'',
                    file:[]
                },
                {
                    id:'',
                    name:'',
                    file:[]
                }
            ],
            columns2:[
                {
                    id:1,
                    label:'建筑物东部'
                },
                {
                    id:2,
                    label:'建筑物西部'
                },
                {
                    id:3,
                    label:'建筑物南部'
                },
                {
                    id:4,
                    label:'建筑物北部'
                }
            ],
        }
    },
    methods:{
        handleAvatarSuccess1(res, file) { 
            console.log(file)
            this.imageUrl1 = URL.createObjectURL(file.raw);
            let file1 = {
                message:'',
                size:file.size,
                status:'success',
                thumb:res.data.url,
                type:'image',
                url:res.data
            }
            this.imageInfo[0].file[0] = file1
        },
        handleAvatarSuccess2(res, file) {
            this.imageUrl2 = URL.createObjectURL(file.raw);
            let file1 = {
                message:'',
                size:file.size,
                status:'success',
                thumb:res.data.url,
                type:'image',
                url:res.data
            }
            this.imageInfo[1].file[0] = file1
        },
        handleChange(e) {
            let name = (this.columns2.find(item => item.id === e)).label
            this.imageInfo[0].name = name
        },
        handleChange1(e) {
            let name = (this.columns2.find(item => item.id === e)).label
            this.imageInfo[1].name = name
        },
        setFlag(data) {
            if (data) {
                this.title = '修改'
                this.$nextTick(() => {
                    this.center = [data.positionLng,data.positionLat]
                    data.device = data.mac
                    this.form = data
                    data.imgInfo[0].name = (this.columns2.find(item => item.id == data.imgInfo[0].id)).label
                    data.imgInfo[1].name = (this.columns2.find(item => item.id == data.imgInfo[1].id)).label
                    this.imageInfo = data.imgInfo
                    if (data.imgInfo.length) {
                        this.imageUrl1 = data.imgInfo[0].file[0].thumb
                        this.imageUrl2 = data.imgInfo[1].file[0].thumb
                    }
                })
            } else {
                this.title = '新增'
                this.form = { 
                    deviceName:'',
                    deviceType:'CF8型智能气象站',
                    webId:'',
                    address:'',
                    positioLng:'',
                    positionLat:'',
                    positionAlt:'',
                    mountAlt:'',
                    remarks:''
                }
                
            }
            this.dialogVisible = true
        },
        submit() { 
            this.$refs.form.validate((valid) => {
                if (valid) {
                    if (this.form.id) {
                        let params = this.form
                        params.currentUserName = $cookies.get("currentUserName")
                        params.deviceId = params.id
                        params.userId = $cookies.get("userInfo").id
                        params.imgInfo = this.imageInfo
                        // params.imgInfo = JSON.stringify(this.imageInfo) 
                        $device.upConnectData(this.form).then(res => {
                            if (res.result === 1) {
                                this.$message.success('修改成功')
                                this.$parent.getLessonListHelper()
                                this.dialogVisible = false
                            }
                        }) 
                    } else {
                        let params = this.form
                        params.currentUserName = $cookies.get("currentUserName")
                        params.userId = $cookies.get("userInfo").id
                        params.imgInfo = this.imageInfo
                        // params.imgInfo = JSON.stringify(this.imageInfo) 
                        $device.setConnectData(this.form).then(res => {
                            if (res.result === 1) {
                                this.$message.success('新增成功')
                                this.$parent.getLessonListHelper()
                                this.dialogVisible = false
                            }
                        })
                    }
                }
            })
            
        },
    }
}
</script>
<style lang="less" scoped>
:deep(.el-input__inner) {
    height: 30px;
}
:deep(.el-input__suffix) {
    top:5px
}
.map {
    margin: 0 auto;
    height: 200px;
    width: 90%;
}
.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
    border: 1px dashed #d9d9d9;
}

</style>